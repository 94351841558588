import { Component, computed, input } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { AbstractControl } from '@angular/forms';
import { switchMap } from 'rxjs';

@Component({
  selector: 'lib-shared-ui-form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss'],
  standalone: true,
})
export class FormErrorsComponent {
  control = input.required<AbstractControl>();
  label = input('This field');

  status = toSignal(toObservable(this.control).pipe(switchMap(control => control?.statusChanges ?? [])));
  errors = computed(() => {
    if (this.status() === 'DISABLED' || !this.control() || !this.control()?.errors || !(this.control()?.dirty || this.control()?.touched)) {
      return [];
    }
    return Object.entries(this.control()?.errors ?? {}).map(([errorType, errorValue]) => this.getErrorMessage(errorType, errorValue));
  });

  private getErrorMessage(errorType: string, errorValue: any) {
    switch (errorType) {
      case 'unique':
      case 'lessThen':
      case 'greaterThan':
      case 'confirmPassword':
        return errorValue;
      case 'required':
        return `${this.label()} is required`;
      case 'email':
        return 'Email must be valid format.';
      case 'mobile':
        return 'Mobile must be valid format.';
      case 'minlength':
        return `${this.label()} must be at least ${errorValue.requiredLength} characters long.`;
      case 'maxlength':
        return `${this.label()} must be less ${errorValue.requiredLength} characters long.`;
      case 'pattern':
        return `${this.label()} does not match the pattern.`;
      case 'passwordMatch':
        return 'Passwords are not identical!';
      case 'passwordStrength':
        return 'Passwords too weak';
      case 'min':
        return `Value cannot be less than ${errorValue.min}`;
      case 'max':
        return `Value cannot be higher than ${errorValue.max}`;
      case 'maxDate':
      case 'minDate':
        return `Date cannot be set in this range`;
      default:
        return typeof errorValue === 'string' ? errorValue : 'Invalid value';
    }
  }
}
